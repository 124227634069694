import React from "react";
import List from "../../component/List/List";
import s from "./firmwarePage.module.scss";
import SEO from "../../component/SEO";

const FirmwarePage = () => {
	const list = [{ id: 0, text: "Прошивки принтера Samsung - от 100 000 сум" }];

	return (
		<div className={s.root}>
			<SEO
				title="Прошивка принтеров в Ташкенте"
				content="В большинстве новых моделей принтеров Samsung, стоит защита от заправки
				картриджей*. При первой заправке необходимо снять защиту от заправок
				(перепрошить принтер). После прошивки принтер будет отображать уровень
				тонера равный 100%"
			></SEO>
			<h1>ПРОШИВКА ПРИНТЕРОВ В ТАШКЕНТЕ</h1>
			<p>
				В большинстве новых моделей принтеров Samsung, стоит защита от заправки
				картриджей*. При первой заправке необходимо снять защиту от заправок
				(перепрошить принтер). После прошивки принтер будет отображать уровень
				тонера равный 100%.
			</p>

			<p className={s.comment}>
				*Производитель устанавливает защиту от заправок, чтобы усложнить жизнь
				заправщикам картриджей и китайским производителям совместимых
				картриджей. После заправки картриджа, принтер снимается с официальной
				гарантии. Однако, из нашего опыта, 95% гарантийных случаев происходит
				еще на заводском стартовом картридже, если принтер отпечатал его, тогда
				все будет в порядке.
			</p>

			<p>
				Если принтер предварительно не перепрошить, то после заправки картриджа,
				принтер печатать не будет.
			</p>

			<p>Прошивка принтера может осуществляется только у нас в офисе</p>

			<h3>НАШИ ЦЕНЫ:</h3>
			<List data={list} />
		</div>
	);
};

export default FirmwarePage;
