import React from "react";
import s from "./sourcepage.module.scss";

import img1 from "../../../assets/img/source1.jpg";
import img2 from "../../../assets/img/source2.jpg";
import SEO from "../../../component/SEO";

const SourcePage = () => {
	return (
		<div className={s.root}>
			<SEO
				title="Что такое ресурс лазерного картриджа?"
				content="Ресурс картриджа – это общее количество страниц, которое можно
				напечатать, используя новый или только что заправленный картридж.
				Исследования показали, что среднее заполнение страницы (указывается как
				процент площади страницы содержащей тонер) для черно-белой страницы
				составляет от 4 до 5%. Поэтому ресурс монохромного (черно-белого)
				картриджа указывается для страниц с 5% (1/20) заполнением листа формата
				А4 тонером."
			></SEO>

			<h3>Что такое ресурс лазерного картриджа?</h3>
			<p>
				Ресурс картриджа – это общее количество страниц, которое можно
				напечатать, используя новый или только что заправленный картридж.
				Исследования показали, что среднее заполнение страницы (указывается как
				процент площади страницы содержащей тонер) для черно-белой страницы
				составляет от 4 до 5%. Поэтому ресурс монохромного (черно-белого)
				картриджа указывается для страниц с 5% (1/20) заполнением листа формата
				А4 тонером. Эти параметры задаются в стандарте ISO/IEC 19752 и
				используются всеми производителями расходных материалов. При цветной
				печати среднее заполнение страницы, как правило, выше. Стандарты цветной
				печати основаны на документах ISO/IEC 19798 и 24711. Из них следует, что
				обычное заполнение страницы при цветной печати составляет 20% (по 5% для
				черного и каждого из трех цветов — голубого, пурпурного и желтого). Если
				среднее заполнение страниц выше, чем в эталонных страницах ISO, то
				следует ожидать значительного сокращения ресурса картриджа по сравнению
				с заявленным. Очевидно, что заполнение страницы является основным
				фактором, влияющим на ресурс картриджа, кроме того на ресурс влияют и
				другие факторы: качество тонера, износ элементов картриджа, влажность
				воздуха, плотность бумаги.
			</p>

			<b>
				Например, если на картридже указан ресурс 3000 страниц, то значит вы
				сможете напечатать 3000 страниц формата А4 при 5% заполнении листа
				тонером. А вот примеры таких страниц:
			</b>

			<div className={s.images}>
				<img src={img1} alt="" />
				<img src={img2} alt="" />
			</div>

			<p>
				Во многом это условные примеры, в реальной жизни ресурс картриджа сильно
				зависит от содержимого страницы: шрифтов, изображений, компоновки
				элементов. Мы составили таблицу с перечнем типовых страниц и
				соответствующим им ресурсом для двух популярных моделей картриджей.
			</p>
			<div className={s.tableDiv}>
				<table>
					<tbody>
						<tr>
							<th>Страница</th>
							<th>Заполнение страницы, %</th>
							<th>Коэффициент</th>
							<th colSpan={2}>
								<div className={s.someth}>
									<div>Ресурс картриджа, стр.</div>
									<div>HP CE285A</div>
									<div>HP Q2612A</div>
								</div>
							</th>
						</tr>
						<tr>
							<td>
								ISO/IEC 19752
								<a href="https://www.itural.ru/wp-content/uploads/2014/08/ISO.jpg">
									пример
								</a>
							</td>
							<td>5,00</td>
							<td>1,00</td>
							<td>1600</td>
							<td>2000</td>
						</tr>
						<tr>
							<td>
								Акт
								<a href="https://www.itural.ru/wp-content/uploads/2014/08/Akt.jpg">
									пример
								</a>
							</td>
							<td>3,74</td>
							<td>0,66</td>
							<td>2424</td>
							<td>3030</td>
						</tr>
						<tr>
							<td>
								Счет-фактура
								<a href="https://www.itural.ru/wp-content/uploads/2014/08/Faktura.jpg">
									пример
								</a>
							</td>
							<td>6,61</td>
							<td>1,17</td>
							<td>1367</td>
							<td>1709</td>
						</tr>
						<tr>
							<td>
								Доверенность
								<a href="https://www.itural.ru/wp-content/uploads/2014/08/Doverennost.jpg">
									пример
								</a>
							</td>
							<td>8,27</td>
							<td>1,47</td>
							<td>1088</td>
							<td>1360</td>
						</tr>
						<tr>
							<td>
								Реферат
								<a href="https://www.itural.ru/wp-content/uploads/2014/08/Referat.jpg">
									пример
								</a>
							</td>
							<td>20,84</td>
							<td>3,69</td>
							<td>433</td>
							<td>542</td>
						</tr>
					</tbody>
					<tfoot></tfoot>
				</table>
			</div>

			<p>
				Пользуясь этой таблицей вы легко сможете оценить на сколько страниц
				хватит вашего картриджа. Например, ресурс вашего картриджа 3000 страниц
				и вы хотите напечатать реферат. Чтобы определить количество страниц на
				которые хватит картриджа при печати реферата, вам нужно ресурс картриджа
				разделить на соответствующий коэффициент: 3000 / 3,69 = 813 страниц.
			</p>
		</div>
	);
};

export default SourcePage;
