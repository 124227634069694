import React from "react";
import { Helmet } from 'react-helmet-async';
import logo from "../../assets/img/seologo.png";

type Props = {
	children?: any;
	title: string;
	content: string;
};

const SEO = ({ title, content, children }: Props) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
				{/* Meta Seo */}
				<meta name="description" content={content} />

				{/* <meta
					property="og:url"
					content=""
				/>
                <meta property="og:type" content="" /> */}

				<meta property="og:title" content={title} />
				<meta property="og:description" content={content} />
				<meta property="og:image" content={logo} />
			</Helmet>
			{children}
		</>
	);
};

export default SEO;
