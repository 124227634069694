import React from "react";
import s from "./plotterrepairpage.module.scss";

import img from "../../assets/img/plotter.png";
import List from "../../component/List/List";
import SEO from "../../component/SEO";

const PlotterRepairPage = () => {
	const list = [
		{
			id: 0,
			text: "Наши специалисты производят ремонт, как на выезде у клиента, так и в сервисном центре!;",
		},
		{ id: 1, text: "Выезжают к клиенту в любой район Ташкента!;" },
		{ id: 2, text: "Ремонт на высоком профессиональном уровне;" },
		{ id: 3, text: "Диагностика платная." },
	];

	return (
		<div className={s.root}>
			<SEO
				title="Ремонт плоттеров и ризографов в Ташкенте"
				content="Сервисный центр 'Eco Print' выполняет профессиональное обслуживание и
				ремонт плоттеров и резограф в Ташкенте. Мы предлагаем комплексные
				услуги по настройке, обслуживанию, диагностике и ремонту плоттеров и
				резограф. Наши специалисты производят ремонт, как на выезде у клиента,
				так и в сервисном центре.! Профессионально и качественно <br /> ВЫЕЗД НА ДОМ в ОФИС. <b>Установка Windows XP, 7, 8, 8.1, 10, 11 + Драйвера + Программы</b> 				Что бы заказать ремонт компьютера звоните нам: +99897 777 65 58. <br />
	  Мы работаем для тех, кто ценит качество!"
			></SEO>

			<h1>Ремонт плоттеров и ризографов в Ташкенте</h1>
			<div className={s.block}>
				<img src={img} alt="" />
				<p>
					Сервисный центр "Eco Print" выполняет профессиональное обслуживание и
					ремонт плоттеров и резограф в Ташкенте. Мы предлагаем комплексные
					услуги по настройке, обслуживанию, диагностике и ремонту плоттеров и
					резограф. Наши специалисты производят ремонт, как на выезде у клиента,
					так и в сервисном центре. Перед ремонтом наши инженеры проводят полную
					диагностику плоттера, для выявления причины поломки и нахождения путей
					ее решения. Также вы получаете предварительный расчёт стоимости
					ремонтных работ - никаких сюрпризов. На все виды ремонта действует
					гарантия до трех лет! У нас трудятся только высококлассные
					специалисты, что подтверждено отзывами большого количества наших
					постоянных клиентов.
				</p>
			</div>
			<List data={list} />
			<b>
				Что бы заказать ремонт или диагностику плоттера или ризографа звоните
				нам: +99897 777 65 58.
				<br />
				Мы работаем для тех, кто ценит качество!
			</b>
		</div>
	);
};

export default PlotterRepairPage;
