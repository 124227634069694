import React from "react";
import List from "../../component/List/List";
import s from "./computerrepairpage.module.scss";
import img from "../../assets/img/pc-repair.jpg";
import SEO from "../../component/SEO";

const ComputerRepairPage = () => {
	const list = [
		{ id: 0, text: "Установка лицензионного Windows" },
		{ id: 1, text: "Установка лицензионного антивируса;" },
		{ id: 2, text: "Установка драйверов;" },
		{ id: 3, text: "Настройка интернет подключения;" },
		{ id: 4, text: "Краткая настройка системы под пользователя;" },
		{ id: 5, text: "Установка Принтера и Настройка. установка;" },
		{ id: 6, text: "Стандартные и прочие программы необходимых для вас;" },
		{ id: 7, text: "Ремонт любой электроники;" },
		{ id: 8, text: "Pемонт ноутбуков;" },
		{ id: 9, text: "Ремонт и обслуживание компьютеров;" },
		{ id: 10, text: "Установка Windows;" },
		{ id: 11, text: "Установка и настройка программного обеспечения;" },
		{ id: 12, text: "Ремонт принтер;" },
		{ id: 13, text: "Заправка картриджей, заправка принтеров;" },
		{ id: 14, text: "Сохранение личной информации;" },
		{
			id: 15,
			text: "Браузеры (Google Chrome, Opera, Яндекс браузер и т.д) На выбор;",
		},
		{ id: 16, text: "Антивирус с неограниченным сроком действия;" },
		{ id: 17, text: "настройка модем wifi роутеров;" },
		{ id: 18, text: "Замена SSD/HDD/ОЗУ;" },
		{
			id: 19,
			text: "Чистка компьютеров от загрязнений и пыли, очистка кулеров, их смазка и замена, смена термопасты;",
		},
		{
			id: 20,
			text: "Замена комплектующих: Жесткие диски, блоки питания, процессоры, мат платы, системы охлаждения, оперативная память и тд.;",
		},
		{ id: 21, text: "Гарантия на выполненные работы от 15 дней до 30 дней." },
	];

	return (
		<div className={s.root}>
			<SEO
				title="Ремонт компьютеров в Ташкенте с выездом"
				content="Быстро! Профессионально и качественно <br /> ВЫЕЗД НА ДОМ в ОФИС. <b>Установка Windows XP, 7, 8, 8.1, 10, 11 + Драйвера + Программы</b> 				Что бы заказать ремонт компьютера звоните нам: +99897 777 65 58. <br />
          Мы работаем для тех, кто ценит качество!"
			></SEO>

			<h1>Ремонт компьютеров в Ташкенте с выездом </h1>
			<img src={img} alt="" />
			<h3>
				Быстро! Профессионально и качественно <br /> ВЫЕЗД НА ДОМ в ОФИС.
			</h3>
			<b>Установка Windows XP, 7, 8, 8.1, 10, 11 + Драйвера + Программы</b>
			<List data={list} />
			<b>
				Что бы заказать ремонт компьютера звоните нам: +99897 777 65 58. <br />
				Мы работаем для тех, кто ценит качество!
			</b>
		</div>
	);
};

export default ComputerRepairPage;
