import React, { useEffect } from "react";
import s from "./thanks.module.scss";
import { Navigate, useNavigate } from "react-router-dom";

const ThanksPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1);
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.thanks}>
          <h2>Спасибо за обращение!</h2>
          <p>Мы с вами свяжемся!</p>
        </div>
      </div>
      <p className={s.text}>
        Страница перенаправится обратно через пару секунд.
      </p>
      <button onClick={handleBack}>Назад</button>
    </div>
  );
};

export default ThanksPage;
